import jsrsasign from 'jsrsasign'
import { publicKey } from '@/api/system'
import Cookies from './cookies'

const KEY_NAME = 'RONCOO_EDUCATION_'
const PUBLIC_KEY = KEY_NAME + 'PUBLIC_KEY'

export function getRsaCode(word) {
  function encrypt(key) {
    // key = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCwi8COElX5pjR7G4kkfO1gxXOwcU+hqxri2TT4gJcdjlsIilh9kz4qvQuj7v+8HwnA4rP4xz70c0h4yiZGP4E1ShJs1ZFjAjYPP1WWypG58LnOPnmTjZf1Uiw7WWAu9lri6a5djs2gRX9H20jIkPgZneej/nXb/AcUrBaCXCFSQQIDAQAB'
    const pKey = `-----BEGIN PUBLIC KEY-----\n ${key} \n-----END PUBLIC KEY-----`
    const sig = jsrsasign.KEYUTIL.getKey(pKey)
    const sigVal = sig.encrypt(word)
    // console.log('加密:',sigVal)
    // const preKey = 'MIICdwIBADANBgkqhkiG9w0BAQEFAASCAmEwggJdAgEAAoGBALCLwI4SVfmmNHsbiSR87WDFc7BxT6GrGuLZNPiAlx2OWwiKWH2TPiq9C6Pu/7wfCcDis/jHPvRzSHjKJkY/gTVKEmzVkWMCNg8/VZbKkbnwuc4+eZONl/VSLDtZYC72WuLprl2OzaBFf0fbSMiQ+Bmd56P+ddv8BxSsFoJcIVJBAgMBAAECgYAE7ULTJCoFOhvCU2nPxx26bYrgNxVNZ3CWuTrhdCM4y67OLaAxEZwdRg0jsXPZEeEBjUIPqgMfgB0bDoHOxDnB5qTuZfPogXm57InzozEqajYq1/1J6iHsnPY/19DlZq351ETJ8aVDLHOgjvYDIGaAp9BezhiGNNEZywcdaaJtbQJBAOMjd6xFwqSzrBVusie6dm+7KMW3ecQuCu83zkns9y1FYbfXOCE6/yBtV/+hiKqSTemoZ0n0sCo7mQEmfI5pt0UCQQDG+pHL2usjnAQ/cLUYTma2dvsdFI8AmPh7QmspW73whvC7Jk52DBrtRZX1lc+GbXyHLv7Fgw2zZqPbbZBgpFDNAkEAyPDFnFgSvBZfdI57eX4R7QKMLW4FgTKiZcLe8rN6cEhjDHqPo4ZH0e4p3XKQLyZDWySqS9j2SEZ1M3lS08JeaQJBAKuxbri+kANMDoZHVJxCmnJ7vDbdlH9pf4+sQheP6Bdx+KskZLOLwnhWgQ1GVamjM77aGFCJwvq4aE9g/SHG50kCQGp2AFTYTdletaDbx7+3SVOVnP2sIQFoC2IpnlmZjfj3j9P6unWjliBwuJPsr9M8PtiiGA7gLgA1bQPK1ri8YZw='
    // const prv = `-----BEGIN PRIVATE KEY-----\n ${preKey} \n-----END PRIVATE KEY-----`

    // 	const preSign =  jsrsasign.KEYUTIL.getKey(prv)
    // 	const decry = preSign.decrypt(sigVal)
    // 	console.log('解密',decry)
    //
    return sigVal
  }
  return new Promise((resolve, reject) => {
    const ret = Cookies.getInClient(PUBLIC_KEY)
    if (ret) {
      resolve(encrypt(ret))
    } else {
      publicKey().then(res => {
        if (res) {
          Cookies.setInClient(PUBLIC_KEY, res)
          resolve(encrypt(res))
        }
      })
    }
  })
}
